import "simplebar/dist/simplebar.min.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";

import { SettingsProvider } from "./contexts/settings-context";
import App from "./app";
import ApplicationStoreProvider from "./contexts/application-store-context";
import { Providers } from "./components/providers";
import { AuthProvider } from "./contexts/auth-provider";

Sentry.init({
  dsn: "https://0f389183c1374d683a020a7f47ddac0a@o1219104.ingest.sentry.io/4506502093471744",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://test-parking-api.signalos.app",
        "https://parking-api.signalos.app",
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        "https://parking-api.signalos.app",
        "https://test-parking-api.signalos.app",
      ],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.PROD ? "production" : "development",
  enabled: import.meta.env.PROD,
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApplicationStoreProvider>
          <AuthProvider>
            <SettingsProvider>
              <Providers>
                <App />
                <Toaster
                  position="top-center"
                  toastOptions={{
                    style: {
                      wordBreak: "break-word",
                    },
                  }}
                />
              </Providers>
            </SettingsProvider>
          </AuthProvider>
        </ApplicationStoreProvider>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root"),
);
